import moment from "moment-timezone";
import Papa from "papaparse";
import jschardet from "jschardet";

import { CSVElement, CSVRow } from "common/CSVTypes";
import { DataScopeOption } from "common/DataScopes";
import StringOption from "common/StringOption";
import axios from "common/ServerConnection";
import { GroupExtendedPermission } from "common/GroupPermissions";
import encodings from "common/encodings";
import {
    ColumnFormat,
    isTextFormat,
    isNumberFormat,
    isDateFormat,
    isGeographyFormat,
    DateFormat,
    NumberFormat,
    GeographyFormat,
    SpreadSheetColumnType,
    NumberFormatType,
} from "common/Canvas";
import { Variable } from "common/Variables";
import { NewVariable } from "common/VariableCreator";
import { stringSessionId } from "common/SessionId";

export const maxChunkSize = 40 * 1024 * 1024;
export const maxUploadFileSize = 80 * 1024 * 1024;

export interface SchemaOptions {
    names: string[];
    units: string[];
    types: Type[];
    formats: (string | null)[];
    panels: Panel[];
    levels: (string | null)[];
    namesToIndices: { [key: string]: number };
}

export function defaultSchemaOptions(): SchemaOptions {
    return {
        names: [],
        types: [],
        units: [],
        panels: [],
        levels: [],
        formats: [],
        namesToIndices: {},
    };
}
export enum UploadType {
    Replace = 1,
    Update = 2,
}

export enum Panel {
    Regular = "regular",
    Geography = "geography",
    Time = "time",
}

export enum Type {
    Str = "str",
    Int = "int",
    Float = "float",
    Datetime = "datetime",
    Bool = "bool",
}

export const columnTypeOptions: ReadonlyArray<string> = ["str", "int", "float"];

export const timeLevelsOptions: ReadonlyArray<string> = ["datetime"];

export const realTimeLiveStreamSlugs: ReadonlyArray<string> = ["FORTNITE"];

export const geographyLevelsOptions: ReadonlyArray<string> = [
    "zipcode",
    "city",
    "state",
];
export const numberLevelsOptions: ReadonlyArray<string> = [
    "Number",
    "Percent",
    "Currency",
];

export const geographyLevelsSelectOptions: StringOption[] = geographyLevelsOptions.map(
    (option) => ({
        label: option,
        value: option,
    })
);
export const numberLevelsSelectOptions: StringOption[] = numberLevelsOptions.map(
    (option) => ({
        label: option,
        value: option,
    })
);

export interface TypeOption {
    label: string;
    value: string;
    type: Type;
    panel: Panel;
    fixedUnit: boolean;
}

export enum ITypeOption {
    Text = 1,
    Number = 2,
    Date = 3,
    Geography = 4,
}

export const typeOptions: ReadonlyArray<TypeOption> = [
    {
        label: "Text",
        value: "Text",
        type: Type.Str,
        panel: Panel.Regular,
        fixedUnit: false,
    },
    {
        label: "Number",
        value: "Number",
        type: Type.Int,
        panel: Panel.Regular,
        fixedUnit: false,
    },
    {
        label: "Date / Time",
        value: "Date / Time",
        type: Type.Datetime,
        panel: Panel.Time,
        fixedUnit: true,
    },
    {
        label: "Geography",
        value: "Geography",
        type: Type.Str,
        panel: Panel.Geography,
        fixedUnit: true,
    },
];

export const panelOptions: ReadonlyArray<Panel> = [
    Panel.Regular,
    Panel.Geography,
    Panel.Time,
];

export const types: ReadonlyArray<Type> = [Type.Int, Type.Float, Type.Str];

export function timeToUTC(
    weekday: number | null,
    timeString: string,
    timezone: string
): { weekday: number | null; timeString: string } {
    const now: Date = new Date();
    let time: moment.Moment = moment.tz(
        `${now.getFullYear()}-${(now.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${now
            .getDate()
            .toString()
            .padStart(2, "0")} ${timeString}`,
        timezone
    );
    if (weekday != null) {
        time.day(weekday);
    }
    const utcTime: moment.Moment = time.clone().tz("GMT");
    return {
        weekday: utcTime.day(),
        timeString: utcTime.format("HH:mm"),
    };
}

export enum LoadFileStatus {
    Success = 1,
    Loading = 2,
    Error = 3,
    NotUploaded = 4,
}

export interface LiveStreamIntervalOption {
    label: string;
    value: string;
}

export interface UploadOption {
    label: string;
    value: UploadType;
}

export const liveStreamIntervalOptions: ReadonlyArray<LiveStreamIntervalOption> = [
    { label: "Week", value: "week" },
    { label: "Day", value: "day" },
];

export const liveStreamIntervalOptionsRealTime: ReadonlyArray<LiveStreamIntervalOption> = [
    { label: "Live", value: "live" },
];

export const uploadOptions: ReadonlyArray<UploadOption> = [
    { label: "Replace", value: UploadType.Replace },
    { label: "Update", value: UploadType.Update },
];

export interface LiveStreamWeekDayOption {
    label: string;
    value: number;
}

export const liveStreamWeekDayOptions: ReadonlyArray<LiveStreamWeekDayOption> = [
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
    { label: "Sunday", value: 0 },
];

export interface LiveStreamUpdateModeOption {
    label: string;
    value: string;
    description: string;
}

export const liveStreamUpdateModeOptions: ReadonlyArray<LiveStreamUpdateModeOption> = [
    {
        label: "none",
        value: "none",
        description: "(Insert the data once and never update it)",
    },
    {
        label: "append",
        value: "append",
        description:
            "(Append new rows. Old rows should not be changed or deleted)",
    },
    {
        label: "replace",
        value: "replace",
        description:
            "(Delete all old data and insert all new data on every update)",
    },
];

export const liveStreamUpdateModeOptionsWithoutAppend: ReadonlyArray<LiveStreamUpdateModeOption> = [
    liveStreamUpdateModeOptions[0],
    liveStreamUpdateModeOptions[2],
];

export enum InputMode {
    None,
    LocalCsv,
    LiveStreamCsv,
    LiveStreamMySQL,
    LiveStreamOracleDB,
    LiveStreamSnowflake,
    LiveStreamAPI,
}

export interface InputBaseState {
    csvHeader: string[];
    csvData: CSVRow[];
    encoding: StringOption;
    selectedFile: File | string | null;
    uploadFileId: string | undefined;
    dataScope: DataScopeOption | undefined;
    newDataScope: DataScopeOption | null;
    loadFileStatus: LoadFileStatus;
    inputMode: InputMode;
    uploadOption: UploadOption;
    permissions: (GroupExtendedPermission | null)[];

    error: string | null;

    liveStreamUpdateMode: LiveStreamUpdateModeOption;
    liveStreamInterval: LiveStreamIntervalOption;
    liveStreamWeekDay: LiveStreamWeekDayOption;
    liveStreamTime: string;
    liveStreamCsvLink: string;
    liveStreamSheetId?: number;

    liveStreamTimezone: StringOption;
    liveStreamEndpointSlug: string;

    liveStreamMySQLHost: string;
    liveStreamMySQLPort: string;
    liveStreamMySQLDatabase: string;
    liveStreamMySQLTable: string;
    liveStreamMySQLLogin: string;
    liveStreamMySQLPassword: string;

    liveStreamOracleDBDsn: string;
    liveStreamOracleDBHost: string;
    liveStreamOracleDBPort: string;
    liveStreamOracleDBServiceName: string;
    liveStreamOracleDBSchema: string;
    liveStreamOracleDBTable: string;
    liveStreamOracleDBLogin: string;
    liveStreamOracleDBPassword: string;

    liveStreamSnowflakeQuery: string;

    liveStreamConfigValid: boolean;

    liveStreamSelectedUpdateMode: LiveStreamUpdateModeOption;
    liveStreamSelectedInterval: LiveStreamIntervalOption;
    liveStreamSelectedWeekDay: LiveStreamWeekDayOption;
    liveStreamSelectedTime: string;
    liveStreamSelectedCsvLink: string;

    liveStreamSelectedEndpointSlug: string;

    liveStreamSelectedMySQLHost: string;
    liveStreamSelectedMySQLPort: string;
    liveStreamSelectedMySQLDatabase: string;
    liveStreamSelectedMySQLTable: string;
    liveStreamSelectedMySQLLogin: string;
    liveStreamSelectedMySQLPassword: string;

    liveStreamSelectedOracleDBHost: string;
    liveStreamSelectedOracleDBPort: string;
    liveStreamSelectedOracleDBServiceName: string;
    liveStreamSelectedOracleDBSchema: string;
    liveStreamSelectedOracleDBTable: string;
    liveStreamSelectedOracleDBLogin: string;
    liveStreamSelectedOracleDBPassword: string;
    liveStreamSelectedOracleDBConnectionMode: string;
    liveStreamSelectedOracleDBDsn: string;

    liveStreamEndpointOptions?: any;
}

export interface InputState extends InputBaseState {
    inputModePopupVisible: boolean;
}

export function variableToColumnFormat(varInfo: Variable): ColumnFormat {
    let columnFormat: ColumnFormat = {
        type: SpreadSheetColumnType.Text,
    };
    if (varInfo.level === "month") {
        columnFormat = {
            type: SpreadSheetColumnType.Month,
        } as ColumnFormat;
        return columnFormat;
    }
    if (varInfo.type === "datetime") {
        columnFormat = {
            type: SpreadSheetColumnType.Date,
            format: varInfo.format,
        } as DateFormat;
    } else if (varInfo.type === "float") {
        columnFormat = {
            type: SpreadSheetColumnType.Number,
            numberType: NumberFormatType.Number,
            decimalPoints: 2,
            useCommaSeparator: true,
        } as NumberFormat;
    } else if (varInfo.type === "int" || varInfo.type === "bool") {
        columnFormat = {
            type: SpreadSheetColumnType.Number,
            numberType: NumberFormatType.Number,
            decimalPoints: 0,
            useCommaSeparator: true,
        } as NumberFormat;
    } else if (varInfo.panel === Panel.Geography) {
        columnFormat = {
            type: SpreadSheetColumnType.Geography,
            level: varInfo.level,
        } as GeographyFormat;
    }
    return columnFormat;
}
export function applyColumnFormatsToSchemaOptions(
    formats: (ColumnFormat | undefined)[],
    schemaOptions: SchemaOptions
): SchemaOptions {
    let newSchemaOptions = { ...schemaOptions };
    for (let column in formats) {
        let format = formats[column];
        if (isTextFormat(format)) {
            newSchemaOptions.types[column] = Type.Str;
            newSchemaOptions.panels[column] = Panel.Regular;
            newSchemaOptions.levels[column] = null;
            newSchemaOptions.formats[column] = null;
        }
        if (isNumberFormat(format)) {
            newSchemaOptions.types[column] =
                format.decimalPoints === 0 ? Type.Int : Type.Float;
            newSchemaOptions.panels[column] = Panel.Regular;
            newSchemaOptions.levels[column] = null;
            newSchemaOptions.formats[column] = null;
        }
        if (isDateFormat(format)) {
            newSchemaOptions.types[column] = Type.Datetime;
            newSchemaOptions.panels[column] = Panel.Time;
            newSchemaOptions.levels[column] = timeLevelsOptions[0];
            newSchemaOptions.formats[column] = format.format;
        }
        if (isGeographyFormat(format)) {
            newSchemaOptions.types[column] = Type.Str;
            newSchemaOptions.panels[column] = Panel.Geography;
            newSchemaOptions.levels[column] = format.level;
            newSchemaOptions.formats[column] = null;
        }
    }
    return newSchemaOptions;
}

export function applyColumnFormat<T extends NewVariable | Partial<NewVariable>>(
    format: ColumnFormat | undefined,
    variable: T
): T {
    if (isTextFormat(format)) {
        variable.type = Type.Str;
        variable.panel = Panel.Regular;
        variable.level = null;
        variable.format = null;
    } else if (isNumberFormat(format)) {
        variable.type = format.decimalPoints === 0 ? Type.Int : Type.Float;
        variable.panel = Panel.Regular;
        variable.level = null;
        variable.format = null;
    } else if (isDateFormat(format)) {
        variable.type = Type.Datetime;
        variable.panel = Panel.Time;
        variable.level = timeLevelsOptions[0];
        variable.format = format.format;
    } else if (isGeographyFormat(format)) {
        variable.type = Type.Str;
        variable.panel = Panel.Geography;
        variable.level = format.level;
        variable.format = null;
    }

    return variable;
}

export function defaultInputBaseState(): InputBaseState {
    return {
        csvHeader: [],
        csvData: [],
        encoding: encodings[0],
        selectedFile: null,
        dataScope: undefined,
        uploadFileId: undefined,
        newDataScope: null,
        loadFileStatus: LoadFileStatus.NotUploaded,
        inputMode: InputMode.None,
        uploadOption: uploadOptions[0],
        permissions: [],



        error: null,

        liveStreamUpdateMode: liveStreamUpdateModeOptions[0],
        liveStreamInterval: liveStreamIntervalOptions[0],
        liveStreamWeekDay: liveStreamWeekDayOptions[0],
        liveStreamTime: "00:00",
        liveStreamCsvLink: "",

        liveStreamEndpointSlug: "",

        liveStreamMySQLHost: "",
        liveStreamMySQLPort: "3306",
        liveStreamMySQLDatabase: "",
        liveStreamMySQLTable: "",
        liveStreamMySQLLogin: "",
        liveStreamMySQLPassword: "",

        liveStreamSelectedOracleDBConnectionMode: "zconnect",

        liveStreamOracleDBDsn: "",
        liveStreamOracleDBHost: "",
        liveStreamOracleDBPort: "1521",
        liveStreamOracleDBServiceName: "",
        liveStreamOracleDBSchema: "",
        liveStreamOracleDBTable: "",
        liveStreamOracleDBLogin: "",
        liveStreamOracleDBPassword: "",
        liveStreamSelectedOracleDBDsn: "",

        liveStreamSnowflakeQuery: "",

        liveStreamConfigValid: false,

        liveStreamTimezone: {
            label: "Local Time Zone",
            value: moment.tz.guess(),
        },
        liveStreamSelectedUpdateMode: liveStreamUpdateModeOptions[0],
        liveStreamSelectedInterval: liveStreamIntervalOptions[0],
        liveStreamSelectedWeekDay: liveStreamWeekDayOptions[0],
        liveStreamSelectedTime: "00:00",
        liveStreamSelectedCsvLink: "",
        liveStreamSheetId: undefined,

        liveStreamSelectedEndpointSlug: "",
        liveStreamEndpointOptions: undefined,

        liveStreamSelectedMySQLHost: "",
        liveStreamSelectedMySQLPort: "3306",
        liveStreamSelectedMySQLDatabase: "",
        liveStreamSelectedMySQLTable: "",
        liveStreamSelectedMySQLLogin: "",
        liveStreamSelectedMySQLPassword: "",

        liveStreamSelectedOracleDBHost: "",
        liveStreamSelectedOracleDBPort: "1521",
        liveStreamSelectedOracleDBServiceName: "",
        liveStreamSelectedOracleDBSchema: "",
        liveStreamSelectedOracleDBTable: "",
        liveStreamSelectedOracleDBLogin: "",
        liveStreamSelectedOracleDBPassword: "",
    };
}

export function defaultInputState(): InputState {
    return {
        ...defaultInputBaseState(),
        inputModePopupVisible: false,
    };
}

export function determineCsvTypes(
    csvData: CSVRow[],
    maxRows: number = 100
): string[] {
    if (csvData.length === 0) {
        return [];
    }
    maxRows = Math.min(csvData.length, maxRows);
    let columnCount: number = 0;
    // First few rows can be empty
    for (let rowI = 0; rowI < maxRows; ++rowI) {
        if (csvData[rowI].length !== 0) {
            columnCount = csvData[rowI].length;
            break;
        }
    }
    if (columnCount === 0) {
        return [];
    }
    // Try to determine types of csv data. Only look at maxRows rows at most.
    let types: string[] = new Array(columnCount);
    let value: CSVElement;
    for (let columnI = 0; columnI < columnCount; ++columnI) {
        for (let rowI = 0; rowI < maxRows; ++rowI) {
            value = csvData[rowI][columnI];
            if (value != null) {
                if (typeof value === "number") {
                    types[columnI] = "float";
                    break;
                } else {
                    // Numbers with commas
                    if (
                        typeof value === "string" &&
                        !isNaN(Number(value.replace(",", "")))
                    ) {
                        types[columnI] = "float";
                    } else {
                        types[columnI] = "str";
                    }
                    break;
                }
            }
        }
        if (types[columnI] == null) {
            types[columnI] = "str";
        }
    }
    return types;
}

const rowLimit = 1572864;

export function parseCsvFile(
    file: File | string,
    onSuccess: (
        csvHeader: string[],
        csvData: CSVRow[],
        csvTypes: string[],
        encoding: StringOption,
        rowCount: number
    ) => void,
    onError: (errors: Papa.ParseError[]) => void,
    preview: number = 100,
    encoding?: StringOption
): void {
    let rowCount = 0;
    let data: CSVRow[] = [];
    let errors: boolean = false;

    let fileReader = new FileReader();
    fileReader.onload = function (e) {
        if (encoding == null) {
            let detectedEncoding: string = jschardet
                .detect(e.target?.result as string)
                .encoding?.toLowerCase();
            encoding =
                encodings.find((enc) => enc.value === detectedEncoding) ??
                encodings[0];
        }

        Papa.parse<CSVRow>(file, {
            encoding: encoding.value,
            dynamicTyping: true,
            skipEmptyLines: true,
            chunk: (results: Papa.ParseResult<CSVRow>, parser: Papa.Parser) => {
                if (results.errors.length > 0) {
                    for (let error of results.errors) {
                        if (error.code !== "UndetectableDelimiter") {
                            errors = true;
                            break;
                        }
                    }
                    if (errors) {
                        errors = true;
                        console.log("Parse csv errors:", results.errors);
                        onError(results.errors);
                        parser.abort();
                    } else {
                        data = data.concat(results.data);
                    }
                } else {
                    rowCount += results.data.length;
                    if (rowCount > rowLimit) {
                        errors = true;
                        onError([
                            {
                                type: "Custom",
                                code: "Custom",
                                message: "The file has too many rows",
                                row: rowCount,
                            },
                        ]);
                        parser.abort();
                    } else if (data.length < preview) {
                        data = data.concat(
                            results.data.slice(0, preview - data.length)
                        );
                    }
                }
            },
            complete: (_results: Papa.ParseResult<CSVRow>) => {
                if (!errors) {
                    let csvHeader: string[] = (data[0] ?? []).map((v) =>
                        (v ?? "").toString()
                    );
                    let csvData: CSVRow[] = data
                        .slice(1)
                        .map((row) =>
                            row.map((item) =>
                                (item as any) instanceof Date
                                    ? ((item as any) as Date).toISOString()
                                    : item
                            )
                        );
                    let csvTypes: string[] = determineCsvTypes(csvData);
                    onSuccess(
                        csvHeader,
                        csvData,
                        csvTypes,
                        encoding as StringOption,
                        rowCount
                    );
                }
            },
        });
    };
    let blob: Blob;
    if (typeof file === "string") {
        blob = new Blob([Buffer.from(file, "base64")]);
    } else {
        blob = file;
    }
    fileReader.readAsBinaryString(blob);
}

export function checkLiveStreamCsv(
    inputState: InputBaseState,
    onSuccess: (preview: any) => void,
    onError: (error: string) => void
) {
    const json = {
        csv: {
            link: inputState.liveStreamSelectedCsvLink,
            sheet_id: inputState.liveStreamSheetId,
        },
        mode: inputState.liveStreamSelectedUpdateMode.value,
        every: {
            interval: inputState.liveStreamSelectedInterval.value,
            weekday:
                inputState.liveStreamSelectedInterval.value === "week"
                    ? inputState.liveStreamSelectedWeekDay.value
                    : undefined,
            time: inputState.liveStreamSelectedTime,
        },
    };
    axios
        .post<ArrayBuffer>("/api/live_streaming_check", json, {
            responseType: "arraybuffer",
        })
        .then((response) => {
            onSuccess(new Blob([response.data]));
        })
        .catch((error) => {
            if (error.response.status === 400) {
                let textDecoder = new TextDecoder("utf-8");
                onError(textDecoder.decode(error.response.data));
            } else {
                onError(
                    `${error.response.status} ${error.response.statusText}`
                );
            }
        });
}

export interface Sheet {
    value: number;
    label: string;
}

export async function getLiveStreamSheets(csvLink: string): Promise<Sheet[]> {
    const json = {
        link: csvLink,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            sheets?: Sheet[];
        }>("/api/get_live_streaming_sheets", json)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.sheets!);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function checkLiveStreamEndpoint(
    inputState: InputBaseState,
    onSuccess: (preview: any) => void,
    onError: (error: string) => void
) {
    const json = {
        api: {
            slug: inputState.liveStreamSelectedEndpointSlug,
            options: inputState.liveStreamEndpointOptions,
        },
        mode: inputState.liveStreamSelectedUpdateMode.value,
        every: {
            interval: inputState.liveStreamSelectedInterval.value,
            weekday:
                inputState.liveStreamSelectedInterval.value === "week"
                    ? inputState.liveStreamSelectedWeekDay.value
                    : undefined,
            time: inputState.liveStreamSelectedTime,
        },
    };
    axios
        .post<ArrayBuffer>("/api/live_streaming_check", json, {
            responseType: "arraybuffer",
        })
        .then((response) => {
            onSuccess(new Blob([response.data]));
        })
        .catch((error) => {
            if (error.response.status === 400) {
                let textDecoder = new TextDecoder("utf-8");
                onError(textDecoder.decode(error.response.data));
            } else {
                onError(
                    `${error.response.status} ${error.response.statusText}`
                );
            }
        });
}

export function checkLiveStreamMySql(
    inputState: InputBaseState,
    onSuccess: (preview: any) => void,
    onError: (error: string) => void
) {
    const json = {
        mysql: {
            host: inputState.liveStreamSelectedMySQLHost,
            port: parseInt(inputState.liveStreamSelectedMySQLPort) || 3306,
            database: inputState.liveStreamSelectedMySQLDatabase,
            table: inputState.liveStreamSelectedMySQLTable,
            login: inputState.liveStreamSelectedMySQLLogin,
            password: inputState.liveStreamSelectedMySQLPassword,
        },
        mode: inputState.liveStreamSelectedUpdateMode.value,
        every: {
            interval: inputState.liveStreamSelectedInterval.value,
            weekday:
                inputState.liveStreamSelectedInterval.value === "week"
                    ? inputState.liveStreamSelectedWeekDay.value
                    : undefined,
            time: inputState.liveStreamSelectedTime,
        },
    };
    axios
        .post<ArrayBuffer>("/api/live_streaming_check", json, {
            responseType: "arraybuffer",
        })
        .then((response) => {
            onSuccess(new Blob([response.data]));
        })
        .catch((error) => {
            if (error.response.status === 400) {
                let textDecoder = new TextDecoder("utf-8");
                onError(textDecoder.decode(error.response.data));
            } else {
                onError(
                    `${error.response.status} ${error.response.statusText}`
                );
            }
        });
}

export function checkLiveStreamOracleDB(
    inputState: InputBaseState,
    onSuccess: (preview: any) => void,
    onError: (error: string) => void
) {
    const json = {
        oracledb: {
            host: inputState.liveStreamSelectedOracleDBHost,
            port: parseInt(inputState.liveStreamSelectedOracleDBPort) || 1521,
            service_name: inputState.liveStreamSelectedOracleDBServiceName,
            schema: inputState.liveStreamSelectedOracleDBSchema || undefined,
            table: inputState.liveStreamSelectedOracleDBTable,
            login: inputState.liveStreamSelectedOracleDBLogin,
            password: inputState.liveStreamSelectedOracleDBPassword,
            mode: inputState.liveStreamSelectedOracleDBConnectionMode,
            dsn: inputState.liveStreamOracleDBDsn,
        },
        mode: inputState.liveStreamSelectedUpdateMode.value,
        every: {
            interval: inputState.liveStreamSelectedInterval.value,
            weekday:
                inputState.liveStreamSelectedInterval.value === "week"
                    ? inputState.liveStreamSelectedWeekDay.value
                    : undefined,
            time: inputState.liveStreamSelectedTime,
        },
    };
    axios
        .post<ArrayBuffer>("/api/live_streaming_check", json, {
            responseType: "arraybuffer",
        })
        .then((response) => {
            onSuccess(new Blob([response.data]));
        })
        .catch((error) => {
            if (error.response.status === 400) {
                let textDecoder = new TextDecoder("utf-8");
                onError(textDecoder.decode(error.response.data));
            } else {
                onError(
                    `${error.response.status} ${error.response.statusText}`
                );
            }
        });
}

export function replaceSchema(
    names: string[],
    types: string[],
    units: string[],
    panels: string[],
    formats: (string | null)[],
    levels: (string | null)[],
    columnEnabled: boolean[],
    dataScope: DataScopeOption,
    pickUniqueName: boolean = false,
    permissions: GroupExtendedPermission[] | undefined,
    onSuccess: (dataTableIdx: string | number) => void,
    onError: (errorMessage: string) => void,
    abortController?: AbortController,
    moduleId?: number | string | null
) {
    let jsonRequest = {
        names: names.filter((_name, index) => columnEnabled[index]),
        types: types.filter((_panel, index) => columnEnabled[index]),
        units: units.filter((_panel, index) => columnEnabled[index]),
        panels: panels.filter((_panel, index) => columnEnabled[index]),
        formats: formats.filter((_panel, index) => columnEnabled[index]),
        levels: levels.filter((_panel, index) => columnEnabled[index]),
        permissions: permissions?.map((permission) => ({
            group_id: permission.group_id,
            permission_type: permission.permission_type,
        })),
        data_table_idx: undefined as string | number | undefined,
        data_table_name: "",
        pick_unique_name: pickUniqueName,
        module_id: moduleId,
        update_id: stringSessionId(),
    };
    jsonRequest.data_table_idx = dataScope.value;
    jsonRequest.data_table_name = dataScope.label;

    axios
        .post<{
            success: boolean;
            error_msg: string;
            data_table_idx?: number | string;
        }>("/api/replace_schema", jsonRequest, {
            signal: abortController?.signal,
        })
        .then((response) => {
            if (response.data.success === false) {
                onError(response.data.error_msg);
            } else {
                onSuccess(response.data.data_table_idx!);
            }
        })
        .catch((error) => {
            if (error.name !== "AbortError") {
                onError(String(error));
            }
        });
}

export type UploadFormatError = "date" | "number";

export async function uploadData(
    dataTableIdx: string | number,
    inputState: InputBaseState,
    onSuccess: (roomId: string) => void,
    onError: (error: string, formatError?: UploadFormatError) => void,
    columnsEnabled?: boolean[],
    forceDateToNans?: boolean,
    forceNumbersToNull?: boolean,
    isGuidanceWizardTemplate?: boolean
): Promise<void> {
    let formData = new FormData();

    let json: {
        data_table_idx: number | string;
        encoding: string;
        columns_enabled?: boolean[];
        mode?: string;
        csv?: {
            link?: string;
            file_id?: string;
            sheet_id?: number;
        };
        mysql?: {
            host: string;
            port: number;
            database: string;
            table: string;
            login: string;
            password: string;
        };
        oracledb?: {
            host?: string;
            port?: number;
            service_name?: string;
            schema?: string;
            table: string;
            login: string;
            password: string;
            dsn?: string;
        };
        api?: {
            slug: string;
            options?: any;
        };
        every?: {
            interval: string;
            weekday?: number;
            time: string;
        };
        rerun_operations?: boolean;
        force_date_to_nans?: boolean;
        force_numbers_to_null?: boolean;
        is_guidance_wizard_template?: boolean;
        update_id?: string;
    } = {
        update_id: stringSessionId(),
        force_date_to_nans: forceDateToNans,
        force_numbers_to_null: forceNumbersToNull,
        is_guidance_wizard_template: isGuidanceWizardTemplate,
        data_table_idx: dataTableIdx,
        encoding: inputState.encoding.value,
        columns_enabled: columnsEnabled,
        mode: "none",
        rerun_operations: inputState.uploadOption.value === UploadType.Update,
    };
    if (inputState.inputMode === InputMode.LocalCsv) {
        if (inputState.uploadFileId == null) {
            formData.append("content", inputState.selectedFile!);
        } else {
            json.csv = {
                file_id: inputState.uploadFileId,
            };
        }
        formData.append("metadata", JSON.stringify(json));
    } else if (inputState.inputMode === InputMode.LiveStreamCsv) {
        json = {
            ...json,
            csv: {
                link: inputState.liveStreamSelectedCsvLink,
                sheet_id: inputState.liveStreamSheetId,
            },
            mode: inputState.liveStreamSelectedUpdateMode.value,
            every: {
                interval: inputState.liveStreamSelectedInterval.value,
                weekday:
                    inputState.liveStreamSelectedInterval.value === "week"
                        ? inputState.liveStreamSelectedWeekDay.value
                        : undefined,
                time: inputState.liveStreamSelectedTime,
            },
        };
        formData.append("metadata", JSON.stringify(json));
    } else if (inputState.inputMode === InputMode.LiveStreamAPI) {
        json = {
            ...json,
            api: {
                slug: inputState.liveStreamSelectedEndpointSlug,
                options: inputState.liveStreamEndpointOptions,
            },
            mode: inputState.liveStreamSelectedUpdateMode.value,
            every: {
                interval: inputState.liveStreamSelectedInterval.value,
                weekday:
                    inputState.liveStreamSelectedInterval.value === "week"
                        ? inputState.liveStreamSelectedWeekDay.value
                        : undefined,
                time: inputState.liveStreamSelectedTime,
            },
        };
        formData.append("metadata", JSON.stringify(json));
    } else if (inputState.inputMode === InputMode.LiveStreamMySQL) {
        json = {
            ...json,
            mysql: {
                host: inputState.liveStreamSelectedMySQLHost,
                port: parseInt(inputState.liveStreamSelectedMySQLPort) || 3306,
                database: inputState.liveStreamSelectedMySQLDatabase,
                table: inputState.liveStreamSelectedMySQLTable,
                login: inputState.liveStreamSelectedMySQLLogin,
                password: inputState.liveStreamSelectedMySQLPassword,
            },
            mode: inputState.liveStreamSelectedUpdateMode.value,
            every: {
                interval: inputState.liveStreamSelectedInterval.value,
                weekday:
                    inputState.liveStreamSelectedInterval.value === "week"
                        ? inputState.liveStreamSelectedWeekDay.value
                        : undefined,
                time: inputState.liveStreamSelectedTime,
            },
        };
        formData.append("metadata", JSON.stringify(json));
    } else if (inputState.inputMode === InputMode.LiveStreamOracleDB) {
        json = {
            ...json,
            oracledb: {
                host: inputState.liveStreamSelectedOracleDBHost,
                port:
                    parseInt(inputState.liveStreamSelectedOracleDBPort) || 1521,
                service_name: inputState.liveStreamSelectedOracleDBServiceName,
                schema:
                    inputState.liveStreamSelectedOracleDBSchema || undefined,
                table: inputState.liveStreamSelectedOracleDBTable,
                login: inputState.liveStreamSelectedOracleDBLogin,
                password: inputState.liveStreamSelectedOracleDBPassword,
                dsn: inputState.liveStreamOracleDBDsn,
            },
            mode: inputState.liveStreamSelectedUpdateMode.value,
            every: {
                interval: inputState.liveStreamSelectedInterval.value,
                weekday:
                    inputState.liveStreamSelectedInterval.value === "week"
                        ? inputState.liveStreamSelectedWeekDay.value
                        : undefined,
                time: inputState.liveStreamSelectedTime,
            },
        };
        formData.append("metadata", JSON.stringify(json));
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            room_id?: string;
            format_error?: UploadFormatError;
        }>("/api/replace_current_data", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((response) => {
            if (response.data.success === false) {
                onError(response.data.error_msg, response.data.format_error);
            } else {
                onSuccess(response.data.room_id!);
            }
        })
        .catch((error) => {
            onError(String(error));
        });
}

export async function cancelUploadData(): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/cancel_replace_current_data")
        .then((response) => {
            if (response.data.success === false) {
                return Promise.reject(response.data.error_msg);
            } else {
                return Promise.resolve();
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function uploadFileToBackend(
    dataTableIdx: string | number,
    blob: Blob,
    fileId?: string,
    abortController?: AbortController
): Promise<{ fileId: string; uploadSize: number }> {
    let formData = new FormData();

    let json: {
        data_table_idx: number | string;
        file_id?: string;
        update_id?: string;
    } = {
        update_id: stringSessionId(),
        data_table_idx: dataTableIdx,
        file_id: fileId,
    };
    formData.append("content", blob);
    formData.append("metadata", JSON.stringify(json));

    let url = "/api/upload_file";
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            file_id?: string;
            upload_size?: number;
        }>(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            signal: abortController?.signal,
        })
        .then((response) => {
            if (response.data.success === false) {
                return Promise.reject(response.data.error_msg);
            } else {
                return Promise.resolve({
                    fileId: response.data.file_id!,
                    uploadSize: response.data.upload_size!,
                });
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function writeCustomRegressionSummary(
    dataScope: DataScopeOption,
    regressionResults: any,
    moduleId?: number | string | null
): Promise<number | string> {
    let jsonRequest = {
        regression_results: regressionResults,
        data_table_idx: undefined as string | number | undefined,
        data_table_name: "",
        module_id: moduleId,
        update_id: stringSessionId(),
    };
    jsonRequest.data_table_idx = dataScope.value;
    jsonRequest.data_table_name = dataScope.label;
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            data_table_idx?: number | string;
        }>("/api/write_custom_regression_summary", jsonRequest)
        .then((response) => {
            if (response.data.success === false) {
                return Promise.reject(response.data.error_msg);
            } else {
                return Promise.resolve(response.data.data_table_idx!);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}
