import React from "react";
import { observer } from "mobx-react";
import { ReactComponent as WizardIcon } from "icons/guidance_wizard/wizard.svg";
import ScaledPage from "common/ScaledPage";

import styles from "./main.module.css";

import DemoBot from './Demobot';


const MainComponent = observer(() => { 
    return (
        <ScaledPage>            
            <DemoBot />        
        </ScaledPage>
    );
});

export { MainComponent };

// The route for the page
export let route: string = "/demobot",
    // Specify the internal name of the page here #
    section: string = "Demobot",
    // Information for the top left menu. You can omit leftPanelItem, then the page
    // will not be shown in the top left menu.
    leftPanelItem = {
        // This name will be displayed in the top left menu
        section: "Demo Bot",
        // The menu entry will open this URL
        href: "demobot",
        // The menu entry will have this icon
        icon: <WizardIcon className={styles.icon} />,
    },
    // Only users that have the "NewPage" permission will see the menu entry
    requirePermission: string = "Demobot";
